import { ISOCountryCode, localizedCountryName } from '@pmi.web/countries';
import { DropdownInput } from '@pmi.web/react-inputs';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectCustomerCountryProps {
  readonly onChange: (country: ISOCountryCode) => void;
  readonly activeCountry?: ISOCountryCode | undefined;
}

export function SelectCustomerCountry({
  activeCountry,
  onChange
}: ISelectCustomerCountryProps) {
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const { availableCountries } = useRegistrationContext();

  const options = useMemo(() => {
    let options: ReadonlyArray<{ label: string; value: string }> = [];

    if (availableCountries) {
      options = availableCountries.map(country => ({
        label: localizedCountryName(country.countryCode, language),
        value: country.countryCode
      }));
    }

    return options;
  }, [availableCountries, language]);

  const value = useMemo(() => {
    let value: string | undefined;

    if (availableCountries) {
      const selectedCountry = availableCountries.find(
        country => country.countryCode === activeCountry
      );

      if (selectedCountry) {
        value = selectedCountry.countryCode;
      }
    }

    return value;
  }, [activeCountry, availableCountries]);

  return (
    <DropdownInput
      label={t('Select your country')}
      value={value}
      options={options}
      onChange={event => onChange(event.target.value as ISOCountryCode)}
    />
  );
}
