declare global {
  interface Window {
    clarity: {
      (operation: unknown, property: unknown, value: unknown): void;
      q: unknown[];
    };
  }
}

function setupMicrosoftClarity(
  c: Window,
  l: Document,
  a: 'clarity',
  r: 'script',
  i: string,
  t?: HTMLScriptElement,
  y?: HTMLScriptElement
) {
  c[a] =
    c[a] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = true;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode?.insertBefore(t, y);
}

export function setClarityTag(tagName: unknown, value: unknown) {
  if (window.clarity) {
    window.clarity('set', tagName, value);
  }
}

export function initClarity() {
  return setupMicrosoftClarity(
    window,
    document,
    'clarity',
    'script',
    process.env.REACT_APP_MS_CLARITY_ID ?? ''
  );
}
