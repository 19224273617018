import { UserManager, UserManagerSettings } from 'oidc-client-ts';

import { APP_URL } from '../constants/Env';

const OidcSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_IDSRV_API ?? '',
  client_id: process.env.REACT_APP_IDSRV_CLIENT_ID ?? '',
  redirect_uri: `${APP_URL}/${process.env.REACT_APP_IDSRV_REDIRECT_ROUTE}`,
  response_type: 'code',
  scope: process.env.REACT_APP_IDSRV_SCOPE,
  loadUserInfo: true
};

export async function startSignIn({
  nonceToken,
  prospectId,
  currentState
}: {
  nonceToken: string;
  prospectId: string;
  currentState: string;
}) {
  const manager = new UserManager({
    ...OidcSettings,
    acr_values: `otp:${nonceToken} prospect:${prospectId}`,
    automaticSilentRenew: nonceToken ? false : true
  });

  await manager.signinRedirect({ state: currentState });
}

export async function completeSignin(): Promise<string> {
  const manager = new UserManager({
    ...OidcSettings,
    automaticSilentRenew: true
  });

  const signedInUser = await manager.signinCallback();

  // If successfully authenticated, restore window location
  if (signedInUser && !signedInUser.expired) {
    let state = '{}';

    if (signedInUser.state && typeof signedInUser.state === 'string') {
      state = signedInUser.state;
    }

    return state;
  }

  throw void 0;
}
