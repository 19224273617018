import { ISOCountryCode } from '@pmi.web/countries';
import { IAvailableCountry } from 'types/ICountries';

export function sortAvailableCountriesByShopId(
  availableCountries: ReadonlyArray<IAvailableCountry>,
  targetShopId: string,
  targetCountryCode: ISOCountryCode
): IAvailableCountry[] {
  const countries = [...availableCountries];

  countries.sort((a, b) => {
    // Prioritize exact match on both shopId and countryCode
    if (a.shopId === targetShopId && a.countryCode === targetCountryCode)
      return -1;
    if (b.shopId === targetShopId && b.countryCode === targetCountryCode)
      return 1;

    // Prioritize match on shopId only
    if (a.shopId === targetShopId) return -1;
    if (b.shopId === targetShopId) return 1;

    // Finally, sort alphabetically by shopId without locale consideration
    return a.shopId > b.shopId ? 1 : -1;
  });

  return countries;
}
