import { type IAddress } from '@pmi.web/react-user-forms';

export interface ProspectData {
  readonly id?: string;
  readonly sponsorId?: string;
  readonly locale?: string;
  readonly address?: IAddress;
}

export interface PropsectResumeData {
  readonly status: 'NeedsResumption';
  readonly hint: string;
}

export interface CustomerData {
  readonly sponsorId?: string;
  readonly locale?: string;
  readonly address?: IAddress;
}

export interface CredentialsData {
  readonly accessToken: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}

export enum RegistrationStartupType {
  ProspectOnly,
  CountrySpecific,
  ShopSpecific
}
