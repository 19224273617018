export function openWindow(name: string, url: string | Promise<string>) {
  if (typeof url === 'string') {
    window.open(url, name);
    return;
  }

  const newWindowContext = window.open('', name);
  url.then(l => {
    if (newWindowContext) {
      newWindowContext.location = l;
    }
  });
}
