import { useMutation } from '@tanstack/react-query';
import { confirmEmail } from 'services/UserManagementService';

export function useEmailConfirmation() {
  const mutation = useMutation({
    mutationFn: async ({
      email,
      userId,
      redirectUrl
    }: {
      readonly userId: string;
      readonly email: string;
      readonly redirectUrl?: string;
    }) => {
      const response = await confirmEmail(userId, email, redirectUrl);

      if (response.ok) {
        return undefined;
      }

      const json = await response.json();

      if (json?.errors?.email?.includes('Bad input for resumption')) {
        throw {
          error: 'invalid_email'
        };
      }

      throw {
        error: 'validation_error'
      };
    }
  });

  return {
    confirmEmail: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: (mutation.error ?? undefined) as unknown | undefined
  };
}
