import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ThemeManager } from '@pmi.web/react-theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicRouter } from 'routers/PublicRouter';
import { reactPlugin } from 'services/ApplicationInsightsService';

import { ErrorBoundary } from './ErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeManager />
        <AppInsightsContext.Provider value={reactPlugin}>
          <PublicRouter />
        </AppInsightsContext.Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
