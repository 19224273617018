import { useEffect } from 'react';
import { completeSignin } from 'services/AuthService';

export function AuthCallbackPage() {
  // use this variable to detect duplicate useEffect() calls in dev
  let _finishSigninStarted = false;

  useEffect(() => {
    const finishSignin = async () => {
      if (_finishSigninStarted) {
        return;
      }

      _finishSigninStarted = true;

      const stateSerialized = await completeSignin();
      const state = JSON.parse(stateSerialized);

      console.log(
        `Received State from Identity server ${JSON.stringify(state)}`
      );

      if (state.redirectUrl) {
        window.location.replace(state.redirectUrl);
      } else {
        let redirectPath = `registrationComplete?lang=${state.locale}`;

        if (state.shopUrl) {
          const shopUrl = new URL(state.shopUrl);
          shopUrl.searchParams.append('autosignin', 'true');

          redirectPath += `&shopUrl=${encodeURIComponent(shopUrl.toString())}`;
        }

        window.location.replace(redirectPath);
      }
    };

    finishSignin();
  }, []);

  return <></>;
}
