import { isISOCountryCode, parseCountryCode } from '@pmi.web/countries';
import { IAvailableCountry, IAvailableCountryApi } from 'types/ICountries';

export async function getAvailableCountries(): Promise<
  ReadonlyArray<IAvailableCountry>
> {
  const response = await fetch(`${process.env.REACT_APP_SHOPS_API}/countries`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const textResponse = await response.text();
  let jsonResponse: ReadonlyArray<IAvailableCountryApi> = [];

  try {
    jsonResponse = await JSON.parse(textResponse);
  } catch (e) {
    //
  }

  if (response.ok) {
    return jsonResponse
      .filter(countryShop => isISOCountryCode(countryShop.countryCode))
      .map(country => ({
        ...country,
        countryCode: parseCountryCode(country.countryCode)
      }));
  }

  throw response;
}
