import { useCallback, useState } from 'react';

interface IUseBooleanFns {
  readonly setTrue: () => void;
  readonly setFalse: () => void;
  readonly toggle: () => void;
}

export function useBoolean(defaultValue?: boolean): [boolean, IUseBooleanFns] {
  const [value, setValue] = useState<boolean>(defaultValue ?? false);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(stateValue => !stateValue), []);

  return [value, { setTrue, setFalse, toggle }];
}
