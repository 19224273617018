import { CustomerRegistrationV2 } from 'components/customer-registration/CustomerRegistrationV2';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';

import { EmailConfirmationPage } from './email-confirmation/EmailConfirmationPage';

export function RootRoute() {
  const { emailRecoveryHint } = useRegistrationContext();

  if (emailRecoveryHint) {
    return <EmailConfirmationPage />;
  }

  return <CustomerRegistrationV2 />;
}
