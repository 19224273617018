import { createContext } from 'react';
import { IAvailableCountry } from 'types/ICountries';
import { ProspectData, RegistrationStartupType } from 'types/models';

export interface IRegistrationContextValues {
  readonly redirectUrl: string | undefined;
  readonly resumeToken: string | undefined;
  readonly emailRecoveryHint: string | undefined;
  readonly prospectData: ProspectData | undefined;
  readonly availableCountries: ReadonlyArray<IAvailableCountry> | undefined;
  readonly registrationFlow: RegistrationStartupType | undefined;
}

export const RegistrationContext =
  createContext<IRegistrationContextValues | null>(null);
