import { ALLOWED_REDIRECT_DOMAINS } from 'constants/AllowedRedirectDomains';

export function isAllowedRedirectUrl(externalUrl: string) {
  try {
    const url = decodeURIComponent(externalUrl);
    const host = new URL(url).host;
    const isUrlAllowed = ALLOWED_REDIRECT_DOMAINS.find(d => host?.endsWith(d));
    return !!isUrlAllowed;
  } catch (err) {
    return false;
  }
}
