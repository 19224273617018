import { PrimaryButton } from '@pmi.web/react-common';
import { EmailInput } from '@pmi.web/react-inputs';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailConfirmedSuccessfully } from './EmailConfirmedSuccessfully';
import { useEmailConfirmation } from './useEmailConfirmation';

const EMAIL_REGEX = /.+@.+\..+/;

export function EmailConfirmationPage() {
  const { prospectData, redirectUrl, emailRecoveryHint } =
    useRegistrationContext();
  const { t } = useTranslation();

  const { confirmEmail, error, isSuccess, isPending, isError } =
    useEmailConfirmation();

  const [email, setEmail] = useState<string>();

  const onEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue && inputValue.length > 0 && EMAIL_REGEX.test(inputValue)) {
      setEmail(inputValue);
    } else {
      setEmail(undefined);
    }
  };

  const onSubmit = async () => {
    if (!email) {
      return;
    }

    confirmEmail({
      email: email,
      userId: prospectData?.id ?? '',
      redirectUrl: redirectUrl
    });
  };

  const emailErrorMessages = useMemo(() => {
    if (!isError) {
      return [];
    }

    if (error && typeof error === 'object' && 'error' in error) {
      if ((error as { error: string }).error === 'invalid_email') {
        return [t('This email address is not the same you used before.')];
      } else {
        return [t('This email address could not be confirmed.')];
      }
    }

    return [t('Something went wrong')];
  }, [isError, error, t]);

  if (isSuccess) {
    return <EmailConfirmedSuccessfully />;
  }

  return (
    <div className="space-y-sm">
      <h1>{t('Confirm your email address')}</h1>

      {isPending && (
        <div className="min-h-[150px] flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!isPending && (
        <>
          <p>{t('Please enter the email address used during registration.')}</p>
          {emailRecoveryHint && emailRecoveryHint.length > 0 && (
            <p>{t("Here's a hint: {{hint}}", { hint: emailRecoveryHint })}</p>
          )}

          <EmailInput
            value={email}
            label={t('Email address')}
            id="email"
            aria-label={t('Email address')}
            required
            onChange={onEmailInputChange}
            errors={emailErrorMessages}
          />

          <PrimaryButton disabled={!email} onClick={onSubmit}>
            {t('Submit')}
          </PrimaryButton>
        </>
      )}
    </div>
  );
}
