import { RegistrationStartupType } from 'types/models';

export function getRegistrationFlow({
  urlCountry,
  urlProspectId,
  urlSponsorId,
  urlShopId
}: {
  readonly urlCountry: string | undefined;
  readonly urlProspectId: string | undefined;
  readonly urlSponsorId: string | undefined;
  readonly urlShopId: string | undefined;
}): RegistrationStartupType | undefined {
  // Flow 1.
  // Fresh registration under a specific country and sponsor
  if (urlCountry !== undefined && urlSponsorId !== undefined) {
    return RegistrationStartupType.CountrySpecific;
  }

  // Flow 2.
  // Recovery of prospect to finish registration
  if (urlProspectId !== undefined) {
    return RegistrationStartupType.ProspectOnly;
  }

  // Flow 3.
  // Fresh registration for specific shop
  if (urlShopId !== undefined) {
    return RegistrationStartupType.ShopSpecific;
  }

  return undefined;
}
