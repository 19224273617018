import { PrimaryButton } from '@pmi.web/react-common';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'utils/window';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="text-center flex flex-col items-center">
      <h1 className="text-5xl text-error">404</h1>
      <p>{t('Page not found')}</p>

      <br />

      <PrimaryButton
        onClick={() => {
          openWindow('pmebusiness', process.env.REACT_APP_LINK_WEBSHOP ?? '');
        }}
      >
        {t('Go to shop')}
      </PrimaryButton>
    </div>
  );
}
