import { type ICustomerRegistrationFormData } from '@pmi.web/react-user-forms';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useBusinessLogicErrorsHandler(
  registrationError: unknown,
  prospectCreationError: unknown
) {
  const { t } = useTranslation();

  /**
   * Business errors reported by the API.
   * Proceed with caution. You will only find hacks inside until we can
   * know for sure what the UM service is returning in case of errors.
   * (╯°□°）╯︵ ┻━┻
   *
   * For now, we can only brace ourselves and hope for the best!
   */
  const businessLogicErrors = useMemo(() => {
    const errors: Partial<
      Record<
        keyof ICustomerRegistrationFormData | 'address.addressLine1',
        { readonly message: string; readonly type: string }
      >
    > = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const backendError: any = registrationError ?? prospectCreationError;

    if (backendError && typeof backendError === 'object') {
      const knownBackendError: {
        readonly detail: string;
        readonly errors: Record<string, string>;
      } = {
        detail: 'detail' in backendError ? (backendError.detail as string) : '',
        errors:
          'Errors' in backendError
            ? backendError.Errors
            : 'errors' in backendError
            ? backendError.errors
            : {}
      };

      // Duplicate address error
      if (knownBackendError.detail === 'address is flagged as duplicate') {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is already in use')
        };
      } // Bad address error
      else if (
        typeof knownBackendError.errors === 'object' &&
        Object.keys(knownBackendError.errors).find(k => k.startsWith('address'))
      ) {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is invalid')
        };
      }

      // Duplicate email error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['email'] &&
        knownBackendError.errors['email'].includes('email is taken')
      ) {
        errors['email'] = {
          type: 'duplicate_email',
          message: t('Email is already in use')
        };
      }

      // Too young error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['dateOfBirth'] &&
        knownBackendError.errors['dateOfBirth'].includes('18')
      ) {
        errors['dateOfBirth'] = {
          type: 'too_young',
          message: t('You must be at least 18 years old')
        };
      }

      // Tax ID errors
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['taxId'] &&
        knownBackendError.errors['taxId'] === 'Tax Id is missing'
      ) {
        errors['taxId'] = {
          type: 'required',
          message: t('Tax Id is missing')
        };
      }

      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['taxId'] &&
        knownBackendError.errors['taxId'].includes('Invalid Tax Id')
      ) {
        errors['taxId'] = {
          type: 'invalid_taxid',
          message: t('Invalid Tax Id')
        };
      }

      // Unexisting Sponsor errors
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['sponsor'] &&
        knownBackendError.errors['sponsor'].includes('Sponsor does not exist')
      ) {
        errors['sponsorId'] = {
          type: 'invalid_sponsor',
          message: t('Sponsor does not exist')
        };
      }

      // Other errors
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors
      ) {
        Object.keys(knownBackendError.errors)
          .filter(
            k =>
              !k.startsWith('address') &&
              k !== 'email' &&
              k !== 'dateOfBirth' &&
              k !== 'sponsor'
          )
          .forEach(k => {
            errors[k as keyof ICustomerRegistrationFormData] = {
              type: 'unknow_error',
              message: knownBackendError.errors[k] ?? t('Something went wrong')
            };
          });
      }
    }

    return errors;
  }, [registrationError, prospectCreationError, t]);

  return businessLogicErrors;
}
